import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Dialog,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPatients } from "../services";
import DialogModal from "./DialogModal";
import AddPatient from "../components/AddPatient";
import { capitalizeFirstLetter } from "../utils/helper";
import ConfirmDialog from "./ConfirmDialog";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

export default function PatientsTable() {
  const [patientsList, setPatientsList] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [observation, setObservation] = useState("Sin observaciones");
  const [patientToEdit, setpatientToEdit] = useState([]);
  const [editPatientModal, seteditPatientModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteModal, setDeleteModal] = useState([]);
  const [idDelete, setIdDelete] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);

  // Función para abrir el diálogo de confirmación de eliminación
  const handleOpenDeleteDialog = (patient) => {
    setPatientToDelete(patient);
    setOpenDeleteDialog(true);
  };

  // Función para cerrar el diálogo de confirmación de eliminación
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setPatientToDelete(null);
  };

  // Función para manejar el éxito de la eliminación
  const handleDeleteSuccess = () => {
    fetchPatients(); // Actualizar la lista de pacientes después de eliminar
    handleCloseDeleteDialog();
  };

  const handleEditPatient = (patient) => {
    setpatientToEdit(patient);
    seteditPatientModal(true);
  };

  const handleCloseEditPatient = () => {
    seteditPatientModal(false);
    // onClose(); // Cerrar también el diálogo de alerta
  };

  const handleEdit = (id) => {
    console.log(`Editar paciente con id ${id}`);
  };

  const handleView = (id) => {
    console.log(`Ver observaciones del paciente con id ${id}`);
  };

  const handleWithdraw = (id) => {
    console.log(`Registrar retiro del paciente con id ${id}`);
  };

  const handleDelete = (patient) => {
    handleOpenDeleteDialog(patient);
  };

  // Función para obtener la lista de pacientes
  const fetchPatients = async () => {
    try {
      const patients = await getPatients();
      setPatientsList(patients);
    } catch (error) {
      console.error("Error al obtener la lista de pacientes:", error);
    }
  };

  useEffect(() => {
    // Llamar a la función para cargar la lista de pacientes cuando el componente se monta
    fetchPatients();
  }, []);

  const handleUpdateSuccess = () => {
    fetchPatients(); // Volver a cargar la lista de empleados después de modificar un empleado
    seteditPatientModal(false);
    console.log("hola");
  };

  const handleClickOpenModal = (patientObservation) => {
    setObservation(patientObservation);
    setopenModal(true);
  };

  const handleCloseDialogModal = () => {
    setopenModal(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Pacientes
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre y Apellido</TableCell>
              <TableCell>DNI</TableCell>
              <TableCell>Telefono</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientsList.map((patient) => (
              <TableRow key={patient.id}>
                <TableCell>
                  {capitalizeFirstLetter(patient.first_name)}{" "}
                  {capitalizeFirstLetter(patient.last_name)}
                </TableCell>
                <TableCell>{patient.dni}</TableCell>
                <TableCell>{patient.phone_number}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Editar">
                    <IconButton
                      color="primary"
                      onClick={() => handleEditPatient(patient)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Observaciones">
                    <IconButton
                      color="warning"
                      onClick={() => handleClickOpenModal(patient.observations)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Retiros">
                    <IconButton
                      color="success"
                      onClick={() => handleWithdraw(patient.id)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Borrar">
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(patient)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogModal
        content={observation}
        title="Observaciones"
        open={openModal}
        handleClose={handleCloseDialogModal}
      ></DialogModal>
      <Dialog
        open={editPatientModal}
        onClose={handleCloseEditPatient}
        maxWidth="md"
      >
        <AddPatient
          patient={patientToEdit}
          onSubmitSuccess={handleUpdateSuccess}
        />
      </Dialog>
      <ConfirmDeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        patientDni={patientToDelete?.dni} // Usa el operador de encadenamiento opcional (?.)
        patientId={patientToDelete?.id} // Usa el operador de encadenamiento opcional (?.)
        onDeleteSuccess={handleDeleteSuccess}
      />
    </Box>
  );
}
